import React, { FC } from 'react'
import styled from 'styled-components'
import MenuCloseIcon from './MenuCloseIcon'
import ToggleIconImg from './ToggleIconImg'

const MobileMenuWrapper = styled.div`
  display: none;
  padding-right: 0;
  padding-left: 0;

  flex-wrap: wrap;
  @media only screen and (max-width: 780px) {
    display: flex;
  }
`

const BurgerWrapper = styled.div`
  display: inline-block;
  line-height: 3;
`

const BurgerButton = styled.button`
  background: transparent;
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  color: #fff;
  border: none;
  padding: 0.5em;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  vertical-align: middle;
  line-height: 1.85714285714286;
  box-shadow: none;
  margin: 0;
`

const ToggleIcon = styled.span`
  display: inline-flex;
  align-self: center;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.85714285714286;
`
const ToggleIconInner = styled.span`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  align-self: center;
  color: #fff;
  text-align: center;
  font-size: 1.5em;
  font-weight: 400;
  line-height: 1.85714285714286;
`

const MobileMenu: FC<{ isMenuOpen: boolean; onToggleMenu: () => void }> = ({ isMenuOpen, onToggleMenu }) => {
  return (
    <MobileMenuWrapper>
      <BurgerWrapper>
        <BurgerButton onClick={onToggleMenu}>
          <ToggleIcon>
            <ToggleIconInner>
              {!isMenuOpen && <ToggleIconImg />}
              {isMenuOpen && <MenuCloseIcon />}
            </ToggleIconInner>
          </ToggleIcon>
        </BurgerButton>
      </BurgerWrapper>
    </MobileMenuWrapper>
  )
}

export default MobileMenu
