import React, { FC } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import styled from 'styled-components'
import scrollTopImg from '../../images/scroll-top-button.png'

const ScrollTopButtonWrapper = styled.div`
  opacity: 1;
  right: 20px;
  bottom: 20px;
  display: block;
  position: fixed;
  cursor: pointer;
  z-index: 9999;
`

const ScrollTopButtonImg = styled.img`
  width: auto;
  height: auto;
  vertical-align: middle;
  cursor: pointer;
  border: 0;
  max-width: 100%;
`

const ScrollTopButton: FC = () => {
  const handleScrollTop = () => {
    scroll.scrollToTop()
  }
  return (
    <ScrollTopButtonWrapper onClick={handleScrollTop}>
      <ScrollTopButtonImg src={scrollTopImg} alt="top" />
    </ScrollTopButtonWrapper>
  )
}

export default ScrollTopButton
