import React, { FC } from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'

export const MenuItemsWrapper = styled.ul`
  align-self: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 780px) {
    display: none;
  }
`

export const MobileMenuItemsWrapper = styled.ul`
  align-self: center;
  list-style: none;
  margin: 0;
  padding-left: 0;
  position: relative;
  display: none;
  flex-wrap: wrap;
  @media only screen and (max-width: 780px) {
    display: flex;
  }
  position: absolute;
  left: 0;
  width: 100%;
  top: 4em;
  flex-direction: column;
  background: #fff;
  padding: 1em 0;
`

export const MenuItem = styled.li`
  line-height: 30px;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
`

export const MenuItemLink = styled.a`
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -js-display: flex;
  display: flex;

  color: #8d8d8d;
  text-decoration: none;
  padding: 0 1em;
  display: inline-block;
  transition: all 0.2s linear;
  line-height: 30px;
  font-family: 'finlandica', sans-serif;
`

const MenuItemInternalLink = styled(Link)`
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -js-display: flex;
  display: flex;

  color: #8d8d8d;
  text-decoration: none;
  padding: 0 1em;
  display: inline-block;
  transition: all 0.2s linear;
  line-height: 30px;
  font-family: 'finlandica', sans-serif;
  cursor: pointer;
`

export const menuItems = [
  { name: 'ABOUT', url: 'about' },
  { name: 'PRODUCTS', url: 'products' },
  { name: 'CONTACT', url: 'mailto:info@finaccept.com', internal: true }
]

const MenuItems: FC = () => (
  <MenuItemsWrapper>
    {menuItems.map(({ name, url, internal }) => (
      <MenuItem key={name}>
        {!internal && (
          <MenuItemInternalLink activeClass="active" to={url} spy={true} smooth={true} duration={500}>
            {name}
          </MenuItemInternalLink>
        )}
        {internal && <MenuItemLink href={url}>{name}</MenuItemLink>}
      </MenuItem>
    ))}
  </MenuItemsWrapper>
)

export const MobileMenuItems: FC = () => (
  <MobileMenuItemsWrapper>
    {menuItems.map(({ name, url, internal }) => (
      <MenuItem key={name}>
        {!internal && (
          <MenuItemInternalLink activeClass="active" to={url} spy={true} smooth={true} duration={500}>
            {name}
          </MenuItemInternalLink>
        )}
        {internal && <MenuItemLink href={url}>{name}</MenuItemLink>}
      </MenuItem>
    ))}
  </MobileMenuItemsWrapper>
)

export default MenuItems
