import React, { FC } from 'react'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import GlobalStyle from './Components/GlobalStyles'
import { HomePage } from './Pages'
import ContactPage from './Pages/ContactPage'

const App: FC = () => {
  return (
    <>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/contact">
            <ContactPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default App
