import React from 'react'

import styled from 'styled-components'

export const TopBackgroundFilter = styled.span`
  max-width: none;
  opacity: 0.4;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background-color: #3a3a3a !important;
`
