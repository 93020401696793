import styled from 'styled-components'

const HeaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 99;
`

export default HeaderWrapper
