import React from 'react'
import styled from 'styled-components'

const H2 = styled.h2`
  color: #ff6041;
  font-size: 75px;
  scroll-behavior: smooth !important;
  max-width: 1200px;
  font-family: 'Squada One';
  margin-bottom: 20px;
  line-height: 1.3;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  visibility: hidden;
  ${({ visible }: { visible?: boolean }) =>
    !visible
      ? ``
      : `visibility: visible;

  animation-name: bounceInLeft;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  `}
`

export default H2
