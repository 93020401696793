import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'

const TechBlockWrapper = styled.div`
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  padding: 3em;
  animation-duration: 2s;
  word-wrap: break-word;
`

const TechBlockColums = styled.div`
  max-width: 1200px;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  gap: 2em;
  margin-bottom: -100px !important;
  flex-wrap: nowrap !important;
  align-items: normal !important;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }
`

const ColumTechIntro = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  margin: 0;
  padding: 0px 50px 0px 0px;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;

  border-right: 2px solid #c4c4c4;
  @media only screen and (max-width: 780px) {
    border: 0;
  }
`

const TechLeftBorder = styled.div`
  margin-block-start: 0;
  margin-block-end: 0;
  clear: both;
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  height: 1px;
`

const TechLeftSpacer = styled.div`
  height: 150px;
  margin-block-start: 2em;
  margin-block-end: 0;
  clear: both;
  @media only screen and (max-width: 780px) {
    height: 2em;
  }
`

const TechLeftText = styled.div`
  visibility: hidden;
  ${({ visible }: { visible: boolean }) =>
    !visible
      ? ``
      : `
  visibility: visible;
  font-size: 26px;
  text-align: right;
  line-height: 1.85714285714286;
  color: #4b4f58;
  animation-name: bounceInLeft;
  margin-bottom: 1.6em;
  margin-block-start: 2em;
  margin-block-end: 0;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  `}
`

const TechOffset = styled.div`
  height: 100px;
  max-width: 1200px;
  margin-block-start: 2em;
  margin-block-end: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  clear: both;
`

const ColumnTechMiddle = styled.div`
  flex-basis: 20px;
  flex-grow: 0;
  margin: 0;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
`

const ColumnTechRight = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  margin: 0;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
`

const ColumnTechRightSpacer = styled.div``
const ColumnTechRightText = styled.h2`
  color: #297995;
  font-size: 38px;
  font-weight: 400;
  font-family: 'Squada One';
  margin-bottom: 20px;
  line-height: 1.3;
  margin-block-start: 0;
  margin-block-end: 0;
  visibility: hidden;
  ${({ visible }: { visible: boolean }) =>
    !visible
      ? ``
      : `
  animation-name: bounceInRight;
  animation-duration: 2s;
  visibility: visible;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  clear: both;
  `}
`

const TechBlock: FC = () => {
  const [leftVisible, setLeftVisible] = useState<boolean>(false)
  const [rightVisible, setRightVisible] = useState<boolean>(false)
  const handleLeftEnter = () => setLeftVisible(true)
  const handleRightEnter = () => setRightVisible(true)

  return (
    <TechBlockWrapper>
      <TechBlockColums>
        <ColumTechIntro>
          <TechLeftBorder />
          <TechLeftSpacer />
          <TechLeftText visible={leftVisible}>
            <Waypoint onEnter={handleLeftEnter}>
              <span></span>
            </Waypoint>
            This detailed understanding allows us to create the right products for our partner banks. Most payment
            technologies attempt to subvert Community Banks, we empower them.
          </TechLeftText>
        </ColumTechIntro>

        <ColumnTechMiddle />
        <ColumnTechRight>
          <ColumnTechRightText visible={rightVisible}>
            <Waypoint onEnter={handleRightEnter}>
              <span></span>
            </Waypoint>
            Payment Technology built by Bankers, for Bankers. We understand Community Banking, regulatory compliance,
            and payments.
          </ColumnTechRightText>
          <ColumnTechRightSpacer />
        </ColumnTechRight>
      </TechBlockColums>
      <TechOffset />
    </TechBlockWrapper>
  )
}

export default TechBlock
