import React, { FC } from 'react'
import topBackgroundImg from '../../images/fabio-1536x1152.jpg'

import styled from 'styled-components'

const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: none;
  max-height: none;
  -o-object-fit: cover;
  object-fit: cover;
  outline: none;
  border: none;
  box-shadow: none;
`

export const TopBackground: FC = () => {
  return <BackgroundImage src={topBackgroundImg} alt="" />
}
