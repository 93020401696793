import React, { FC } from 'react'
import styled from 'styled-components'

const FormContactWrapper = styled.div`
  position: relative;
  z-index: 99;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
`

const LabelWrapper = styled.div`
  display: flex;
`

const LabelTextWrapper = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
  padding: 0;
  color: #4b4f58;
`

const RequiredWrapper = styled.div`
  color: #c02b0a;

  display: inline-block;
  font-size: 13.008px;
  padding-left: 0.125em;
`

const SubLabel = styled.div`
  font-size: 15px;
  padding-top: 5px;
  color: #4b4f58;
`

const FieldSet = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  gap: 2em;
`

const Column = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const TextInput = styled.input`
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 8px;
  width: 100%;
  max-width: 100%;
  color: #666;
  height: auto;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  background: #fafafa;
  box-shadow: none;
  transition: all 0.2s linear;
`

const TextArea = styled.textarea`
  height: 96px;
  width: 100%;
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding: 8px;
  color: #666;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  background: #fafafa;
  box-shadow: none;
  transition: all 0.2s linear;
  ine-height: 1.85714285714286;
  margin: 0;
  vertical-align: baseline;
  overflow: auto;
`

const Label: FC<{ required?: boolean; children: React.ReactNode }> = ({ required, children }) => {
  return (
    <LabelWrapper>
      <LabelTextWrapper>{children}</LabelTextWrapper>
      {required && <RequiredWrapper>(Required)</RequiredWrapper>}
    </LabelWrapper>
  )
}

const FormContactFooter = styled.div`
  margin: 6px 0 0;
  padding: 16px 0;
  display: flex;
`

const SubmitButton = styled.button`
  margin-bottom: 8px;
  align-self: flex-end;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  color: #ffffff;
  background-color: #0170b9;
  border-radius: 2px;
  padding-top: 15px;
  padding-right: 30px;
  padding-bottom: 15px;
  padding-left: 30px;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
`

const FormContact: FC = () => {
  return (
    <FormContactWrapper>
      <FieldSet>
        <Label required={true}>Name</Label>
        <Row>
          <Column>
            <TextInput />
            <SubLabel>First</SubLabel>
          </Column>
          <Column>
            <TextInput />
            <SubLabel>Last</SubLabel>
          </Column>
        </Row>
      </FieldSet>
      <FieldSet>
        <Row>
          <Column>
            <Label required={true}>Email</Label>
            <TextInput />
          </Column>
          <Column>
            <Label required={true}>Phone</Label>
            <TextInput />
          </Column>
        </Row>
      </FieldSet>
      <FieldSet>
        <Label>Subject</Label>
        <TextInput />
      </FieldSet>
      <FieldSet>
        <Label required={true}>Question or Comment</Label>
        <TextArea />
      </FieldSet>
      <FormContactFooter>
        <SubmitButton>Sumit</SubmitButton>
      </FormContactFooter>
    </FormContactWrapper>
  )
}

export default FormContact
