import React from 'react'
import styled from 'styled-components'

const HeaderInner = styled.div`
  max-width: 1240px;
  min-height: 30px;
  display: grid;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
`

export default HeaderInner
