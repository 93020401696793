import { FC } from 'react'
import styled from 'styled-components'

import sideArrowImgSrc from '../../images/Side-arrow.svg'

const ProductDescriptionInner = styled.div``

const ProductDescriptionWrapper = styled.div``

const ProductDescriptionTitle = styled.h3`
  font-size: 1.6666666666667rem;
  font-family: 'Squada One';
  margin-bottom: 20px;
  line-height: 1.4;
  margin-block-start: 0;
  margin-block-end: 0;
  clear: both;
`

const ProductDescriptionBody = styled.p`
  font-size: 18px;
  margin-bottom: 1.6em;
  margin-block-start: 2em;
  margin-block-end: 0;
`

const ProductDescriptionHr = styled.hr`
  background-color: #c4c4c4;
  color: #c4c4c4;
  border-top: 1px solid #c4c4c4 !important;
  padding: 0;
  height: 0;
  margin: 20px auto;
  margin-block-start: 2em;
  margin-block-end: 0;
  border-bottom: 1px solid;
`

const ProductFigures = styled.figure`
  margin-block-start: 2em;
  margin-block-end: 0;
  margin: 2em 0 1em;
`

const ProductFigureImg = styled.img`
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 100px;
`

const ProductDescription: FC = () => (
  <ProductDescriptionWrapper>
    <ProductDescriptionInner>
      <ProductDescriptionTitle>
        <strong>Pocket</strong>
      </ProductDescriptionTitle>
      <ProductDescriptionBody>
        Pocket is a prepaid card program that allows Community Banks to obtain new customers and participate in card
        transaction economics.
      </ProductDescriptionBody>

      <ProductDescriptionBody>
        <strong>Inquire today to see if Pocket is right for you.</strong>
      </ProductDescriptionBody>
      <ProductDescriptionHr />
      <ProductFigures>
        <ProductFigureImg src={sideArrowImgSrc} />
      </ProductFigures>
    </ProductDescriptionInner>
  </ProductDescriptionWrapper>
)

export default ProductDescription
