import React, { FC, useState } from 'react'
import styled from 'styled-components'
import MenuItems, { MobileMenuItems } from './MenuItems'
import MobileMenu from './MobileMenu'

const MenuWrapper = styled.div`
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-end;
  height: 100%;
  min-height: 0;
  align-items: center;
`

const Menu: FC = () => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)

  return (
    <MenuWrapper>
      <MenuItems />
      {isMenuOpen && <MobileMenuItems />}
      <MobileMenu isMenuOpen={isMenuOpen} onToggleMenu={() => setMenuOpen(!isMenuOpen)} />
    </MenuWrapper>
  )
}

export default Menu
