import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'

import productFigureImgSrc from '../../images/pocket-logo.png'

import H2 from '../H2'
import ProductDescription from './ProductDescription'

const ProductsBlockWrapper = styled.div`
  max-width: none;
  margin-left: auto;
  margin-right: auto;
  padding: 3em;
  margin-bottom: 0;
  background-color: #f5f5f5;
`

const ProductsHr = styled.div`
  background-color: #c4c4c4;
  color: #c4c4c4;
  border-top: 1px solid #c4c4c4 !important;
  max-width: 1200px;
  padding: 0;
  height: 0;
  margin: 20px auto;
  margin-block-start: 2em;
  margin-block-end: 0;
  margin-left: auto !important;
  margin-right: auto !important;
`

const ProductsColumns = styled.div`
  max-width: 1200px;
  margin-bottom: 0;
  margin-block-start: 2em;
  margin-block-end: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  gap: 2em;
  /* (min-width: 782px) */
  flex-wrap: nowrap !important;
  align-items: normal !important;

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    margin-block-end: 6em;
  }
`

const ColumnLeftProducts = styled.div`
  flex-basis: 500px;
`

const ColumnRightProducts = styled.div`
  flex-grow: 1;
`

const PocketLogo = styled.figure`
  visibility: hidden;
  ${({ visible }: { visible: boolean }) =>
    !visible
      ? ``
      : `
  animation-name: bounceInRight;
  visibility: visible;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-play-state: running;
  -moz-animation-play-state: running;
  -ms-animation-play-state: running;
  -o-animation-play-state: running;
  animation-play-state: running;
  `}
  margin-block-start: 0;
  margin-block-end: 0;
  margin: 0 0 1em;
`

const PocketLogoImg = styled.img`
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 171px;
  aspect-ratio: auto 171 / 256;
`

const ProductsBlock: FC = () => {
  const [pocketVisible, setPocketVisisble] = useState<boolean>(false)
  const handlePocketLogoEnter = () => setPocketVisisble(true)
  return (
    <ProductsBlockWrapper>
      <H2 visible={pocketVisible}>Products</H2>
      <ProductsHr />
      <ProductsColumns>
        <ColumnLeftProducts>
          <ProductDescription />
        </ColumnLeftProducts>
        <ColumnRightProducts>
          <PocketLogo visible={pocketVisible}>
            <Waypoint onEnter={handlePocketLogoEnter}>
              <span></span>
            </Waypoint>
            <PocketLogoImg src={productFigureImgSrc} />
          </PocketLogo>
        </ColumnRightProducts>
      </ProductsColumns>
    </ProductsBlockWrapper>
  )
}

export default ProductsBlock
