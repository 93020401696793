import React, { FC } from 'react'

import styled from 'styled-components'

import Logo from '../Logo'
import Menu from '../Menu'
import HeaderInner from './HeaderInner'
import HeaderWrapper from './HeaderWrapper'

const HeaderLeft = styled.div`
  flex-wrap: nowrap;
  display: flex;
  height: 100%;
  min-height: 0;
  align-items: center;
  overflow-wrap: anywhere;
`

const HeaderGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 20px;
  overflow-wrap: anywhere;
`

const Header: FC = () => {
  return (
    <HeaderWrapper>
      <HeaderInner>
        <HeaderGrid>
          <HeaderLeft>
            <Logo />
          </HeaderLeft>
          <Menu />
        </HeaderGrid>
      </HeaderInner>
    </HeaderWrapper>
  )
}

export default Header
