import React, { FC } from 'react'
import styled from 'styled-components'
import FormContact from '../FormContact/FormContact'
import H2 from '../H2'

const FormContactBlockWrapper = styled.div`
  display: flex;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
`

const FormContactBlockInner = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  margin: 4em 0;
  width: 100%;
  background: #f5f5f5;
  position: relative;
  z-index: 99;
`

const FormContactBlock: FC = () => {
  return (
    <FormContactBlockWrapper>
      <FormContactBlockInner>
        <H2>Contact</H2>
        <FormContact />
      </FormContactBlockInner>
    </FormContactBlockWrapper>
  )
}

export default FormContactBlock
