import React, { FC } from 'react'
import styled from 'styled-components'

import logoImg from '../../images/finaccept-logo.svg'

const LogoWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`

const LogoInner = styled.div`
  padding: 1em 0;
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  transition: all 0.2s linear;
  line-height: 1;
  align-self: center;
`

const LogoImgWrapper = styled.span`
  padding-right: 1em;
  line-height: 1;
`

const LogoImg = styled.img`
  max-width: 263px
  transition: all 0.2s linear;
  width: 263px;
  vertical-align: middle;
  height: auto;
  aspect-ratio: auto 1 / 1;
  border: 0;
  line-height: 1;
  color: #aaa;
`

const Logo: FC = () => {
  return (
    <LogoWrapper>
      <LogoInner>
        <LogoImgWrapper>
          <a href="/">
            <LogoImg src={logoImg} alt="FinAccept" />
          </a>
        </LogoImgWrapper>
      </LogoInner>
    </LogoWrapper>
  )
}

export default Logo
