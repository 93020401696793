import React, { FC, useState } from 'react'
import { Element } from 'react-scroll'

import Content from '../Components/Content'
import CoverBlock from '../Components/CoverBlock'

import Header from '../Components/Header'
import PageWrapper from '../Components/Page/PageWrapper'
import ProductsBlock from '../Components/ProductsBlock'
import ScrollTopButton from '../Components/ScrollTopButton'
import TechBlock from '../Components/TechBlock'

const HomePage: FC = () => {
  const [isShowScrollToTopButton, setShowScrollToTopButton] = useState<boolean>()
  return (
    <PageWrapper>
      <Header />
      <Content>
        <CoverBlock
          onLeave={() => {
            setShowScrollToTopButton(true)
          }}
          onEnter={() => {
            setShowScrollToTopButton(false)
          }}
        />

        <Element name="about">
          <TechBlock />
        </Element>
        <Element name="products">
          <ProductsBlock />
        </Element>
      </Content>
      {isShowScrollToTopButton && <ScrollTopButton />}
    </PageWrapper>
  )
}

export default HomePage
