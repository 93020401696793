import React, { FC } from 'react'
import styled from 'styled-components'

import FormContactBlock from '../Components/FormContactBlock'
import Header from '../Components/Header'
import { PageWrapper } from '../Components/Page'
import { TopBackground, TopBackgroundFilter } from '../Components/TopBackground'

const Content = styled.div`
  flex-grow: 1;
  background: #fff;
`

const ContactPage: FC = () => {
  return (
    <PageWrapper>
      <Header />
      <Content>
        <TopBackgroundFilter />
        <TopBackground />
        <FormContactBlock />
      </Content>
    </PageWrapper>
  )
}

export default ContactPage
