import React, { FC } from 'react'
import { Waypoint } from 'react-waypoint'
import { Link } from 'react-scroll'
import styled from 'styled-components'

import { TopBackground, TopBackgroundFilter } from '../TopBackground'

import HexArrowImgSrc from '../../images/hex-arrow.svg'

const CoverBlockWrapper = styled.div`
  min-height: 700px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background-size: cover;
  background-position: 50%;
  min-height: 430px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
`

const CoverInnerContent = styled.div`
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  z-index: 1;
`

const CoverColumns = styled.div`
  margin-bottom: 0;
  display: flex;
  gap: 2em;
  flex-wrap: nowrap;
  align-items: center;
  @media only screen and (max-width: 780px) {
    flex-direction: column;
  }
`

const CoverLeftContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  align-self: flex-end;
  margin: 0;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
`

const TopMargin = styled.div`
  height: 400px;
  margin-block-start: 0;
  margin-block-end: 0;
  clear: both;
  flex-grow: 1;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
  @media only screen and (max-width: 780px) {
    height: 4em;
  }
`

const CoverRightContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  width: 100%;
  align-self: center;
  margin: 0;
  min-width: 0;
  word-break: break-word;
  overflow-wrap: break-word;
`

const CoverRightText = styled.h1`
  color: #ff6041;
  font-size: 60px;
  font-family: 'Squada One', sans-serif;
`

const LeftCoverParagraph = styled.p`
  line-height: 1.2 !important;
  margin: 0px 0px 10px 0px;
  color: #fff;
  font-family: 'finlandica', sans-serif;
  font-size: 20px;
  margin-block-start: 2em;
  margin-block-end: 0;
  animation-name: fadeInUp;
  animation-fill-mode: both;
  padding: 0;
  vertical-align: baseline;
`

const HexArrowFigure = styled.figure`
  margin-block-start: 2em;
  margin-block-end: 0;
  margin: 2em 0 1em;
`

const HexArrowLink = styled(Link)``
const HexArrowImg = styled.img`
  height: auto;
  max-width: 100%;
  vertical-align: bottom;
  width: 60px;
`

const CoverBlock: FC<{ onEnter: () => void; onLeave: () => void }> = ({ onEnter, onLeave }) => {
  return (
    <CoverBlockWrapper>
      <TopBackgroundFilter />
      <TopBackground />
      <CoverInnerContent>
        <CoverColumns>
          <CoverLeftContainer>
            <TopMargin />
            <LeftCoverParagraph className="animation fadeInUp">
              <Waypoint onLeave={onLeave} onEnter={onEnter}>
                <span></span>
              </Waypoint>
              Learn how your bank can join
              <br /> the lucrative payments industry.
            </LeftCoverParagraph>
            <HexArrowFigure>
              <HexArrowLink activeClass="active" to={'products'} spy={true} smooth={true} duration={500}>
                <HexArrowImg src={HexArrowImgSrc} />
              </HexArrowLink>
            </HexArrowFigure>
          </CoverLeftContainer>
          <CoverRightContainer>
            <CoverRightText className="animation fadeInDown">
              Finaccept opens the world of payment processing to Community Banks
            </CoverRightText>
          </CoverRightContainer>
        </CoverColumns>
      </CoverInnerContent>
    </CoverBlockWrapper>
  )
}

export default CoverBlock
